<template>
  <div class="parent">
    <img src="../top_border.png" alt="" />
    <div
      id="app"
      class="w-full h-screen flex flex-col justify-center items-center"
    >
      <HelloWorld msg="Welcome to Your Vue.js App" />
    </div>
  </div>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";

export default {
  name: "App",
  components: {
    HelloWorld,
  },
};
</script>

<style scopped>
img {
  clip-path: polygon(
    100% 0%,
    0% 0%,
    0% 91.95%,
    1% 91.94%,
    2% 91.9%,
    3% 91.82%,
    4% 91.72%,
    5% 91.59%,
    6% 91.43%,
    7% 91.25%,
    8% 91.04%,
    9% 90.81%,
    10% 90.55%,
    11% 90.27%,
    12% 89.97%,
    13% 89.65%,
    14% 89.31%,
    15% 88.96%,
    16% 88.59%,
    17% 88.21%,
    18% 87.82%,
    19% 87.43%,
    20% 87.02%,
    21% 86.61%,
    22% 86.2%,
    23% 85.79%,
    24% 85.39%,
    25% 84.98%,
    26% 84.58%,
    27% 84.19%,
    28% 83.82%,
    29% 83.45%,
    30% 83.1%,
    31% 82.76%,
    32% 82.44%,
    33% 82.14%,
    34% 81.86%,
    35% 81.6%,
    36% 81.37%,
    37% 81.16%,
    38% 80.98%,
    39% 80.82%,
    40% 80.69%,
    41% 80.59%,
    42% 80.52%,
    43% 80.47%,
    44% 80.46%,
    45% 80.47%,
    46% 80.52%,
    47% 80.59%,
    48% 80.69%,
    49% 80.82%,
    50% 80.98%,
    51% 81.17%,
    52% 81.38%,
    53% 81.61%,
    54% 81.87%,
    55% 82.15%,
    56% 82.45%,
    57% 82.77%,
    58% 83.11%,
    59% 83.46%,
    60% 83.83%,
    61% 84.21%,
    62% 84.6%,
    63% 85%,
    64% 85.4%,
    65% 85.81%,
    66% 86.22%,
    67% 86.63%,
    68% 87.04%,
    69% 87.44%,
    70% 87.84%,
    71% 88.23%,
    72% 88.61%,
    73% 88.97%,
    74% 89.32%,
    75% 89.66%,
    76% 89.98%,
    77% 90.28%,
    78% 90.56%,
    79% 90.82%,
    80% 91.05%,
    81% 91.26%,
    82% 91.44%,
    83% 91.6%,
    84% 91.73%,
    85% 91.83%,
    86% 91.9%,
    87% 91.94%,
    88% 91.95%,
    89% 91.94%,
    90% 91.89%,
    91% 91.82%,
    92% 91.72%,
    93% 91.59%,
    94% 91.43%,
    95% 91.24%,
    96% 91.03%,
    97% 90.8%,
    98% 90.54%,
    99% 90.26%,
    100% 89.96%
  );
}
</style>
