var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-auto my-auto border p-6 rounded-xl",attrs:{"id":"parenter"}},[_vm._m(0),_c('div',{staticClass:"form-control"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"input input-info input-bordered",class:[
        _vm.nameFlag
          ? 'input input-warning input-bordered'
          : 'input input-info input-bordered' ],attrs:{"placeholder":"Name","type":"text"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_c('label',{staticClass:"label"},[(_vm.nameFlag)?_c('span',{staticClass:"label-text-alt"},[_vm._v("Please enter name")]):_vm._e()]),_vm._m(2),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phoneNumber),expression:"phoneNumber"}],staticClass:"input input-info input-bordered",class:[
        _vm.flag
          ? 'input input-info input-bordered'
          : 'input input-warning input-bordered' ],attrs:{"placeholder":"Phone Number","type":"tel","pattern":"[+]?[0-9]{9,12}"},domProps:{"value":(_vm.phoneNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phoneNumber=$event.target.value}}}),_c('label',{staticClass:"label"},[(_vm.Invalid)?_c('span',{staticClass:"label-text-alt"},[_vm._v("Please enter valid phone Number")]):_vm._e()]),_vm._m(3),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],staticClass:"textarea h-24 textarea-bordered textarea-info",class:[
        _vm.messageFlag
          ? 'textarea h-24 textarea-bordered textarea-warning'
          : 'textarea h-24 textarea-bordered textarea-info' ],attrs:{"placeholder":"Message"},domProps:{"value":(_vm.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.message=$event.target.value}}}),_c('label',{staticClass:"label"},[(_vm.messageFlag)?_c('span',{staticClass:"label-text-alt"},[_vm._v("Please enter message")]):_vm._e()]),_c('button',{staticClass:"btn modal-button g-recaptcha",class:[
        _vm.buttonFlag ? 'btn modal-button g-recaptcha' : '  btn btn-lg loading' ],attrs:{"data-sitekey":"6LcIHzQeAAAAAIBTSh7QM9WLb18H7WAIfq3n4Pe0"},on:{"click":function($event){{
          _vm.check();
        }}}},[_vm._v(" Send ")])]),_c('Alert',{attrs:{"dialogueFlag":_vm.dialogueFlag}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar mb-2 shadow-lg bg-neutral text-neutral-content rounded-box",attrs:{"id":"navbar"}},[_c('div',{staticClass:"px-2 mx-2"},[_c('span',{staticClass:"text-lg font-bold"},[_vm._v(" Hi! sorry for the inconvenience, we are building a new website "),_c('br'),_vm._v(" for now, fell free to contact us via the form ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"label"},[_c('span',{staticClass:"label-text"},[_vm._v("Name")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"label"},[_c('span',{staticClass:"label-text"},[_vm._v("Phone Number")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"label"},[_c('span',{staticClass:"label-text"},[_vm._v("Message")])])}]

export { render, staticRenderFns }