<template>
  <div class="alert alert-success" v-if="dialogueFlag === true">
    <div class="flex-1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        class="w-6 h-6 mx-2 stroke-current"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
        ></path>
      </svg>
      <label>Message has been sent successfully!</label>
    </div>
  </div>
</template>

<script>
export default {
  name: "Alert",
  props: {
    dialogueFlag: Boolean,
  },
};
</script>
